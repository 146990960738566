<template>
  <div class="left-space-desktop"></div>
  <div class="page-content w-100">
    <div class="row mt-2 ">
      <div class="col-sm-12 usersStyle">
        <div class="row mt-2">


          <h4 class="RegisterPatientText">Registered patients</h4>
        </div>
        <div class="row" style="display: flex;margin: 10px; cursor: pointer;">
          <div class="col-lg-3 col-md-3 col-6 mt-3" @click="openMobileRecord(userRecordsData)">
            <div class="image-fix">
              <div>
                <div class="text-center name-fix">{{ userRecordsData.firstName }}</div>
                <img class="img-women icon" src="@/assets/images/Male.png" v-if="userRecordsData.gender === 'Male'" />
                <img class="img-women icon" src="@/assets/images/Female.png"
                  v-else-if="userRecordsData.gender === 'Female'" />
                <img class="img-women icon" src="@/assets/images/nogender.png"
                  v-else-if="userRecordsData.gender === 'Others'">
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-6 mt-3" v-for="(value) in familyRecords" :key="value.id"
            @click="openFamilyRecords(value)">
            <div class="image-fix">
              <div>
                <div class="text-center name-fix">{{ value.firstName }}</div>
                <img class="img-women icon" src="@/assets/images/Male.png" v-if="value.gender === 'Male'" />
                <img class="img-women icon" src="@/assets/images/Female.png" v-else-if="value.gender === 'Female'" />
                <img class="img-women icon" src="@/assets/images/nogender.png" v-else-if="value.gender === 'Others'">
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-6 mt-3" v-if="!closeAddPatient">
            <div class="image-fix">
              <div class="text-center name-fix">Add family member</div>
              <img class="img-women icon" src="@/assets/images/plus-add.png" @click="addPatient()" />
            </div>
          </div>
        </div>
        <div class="mt-3 mb-2">
          Note: <span>Select a person to view his/her medical records.</span>
        </div>
        <!-- <div class="hr-border"></div> -->

        <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->

        <div class="row">
          <div class="col-12">
            <div class="mt-3" v-if="selectMobileRecord">
              <h3 class="medical-record">Medical records of {{ selectMobileRecord.firstName }}</h3>
              <h3 class="medical-record">RxIx ID : {{ selectMobileRecord.id }}-{{ 1 }}</h3>
              <doctorPatientView :upMobileRecord="upMobileRecord" :key="isLoading"></doctorPatientView>
            </div>
            <div class="mt-3" v-if="isNewUser">
              <h3 class="medical-record">Medical records of {{ newUserData.firstName }}</h3>
              <h3 class="medical-record">RxIx ID : {{ newUserData.id }}-{{ 1 }}</h3>
              <doctorPatientView :upMobileRecord="newUserData" :key="isLoading"></doctorPatientView>
            </div>
            <div class="mt-3" v-if="selectFamilyRecord">
              <h3 class="medical-record">Medical records of {{ selectFamilyRecord.firstName }}</h3>
              <h3 class="medical-record">RxIx ID : {{ selectFamilyRecord.users.id }}-{{ 1 + selectFamilyRecord.memberCount
                }}</h3>
              <doctorPatientFamilyView :upFamily="upFamily" :key="isLoading + upFamily.id"></doctorPatientFamilyView>
            </div>
          </div>
        </div>
        <div class="row mb-3" v-if="openAddConsultation">
          <div class="col-12 mt-2 horizondalLine">
            <div class="hr-border"></div>
          </div>
        </div>

        <div v-if="openAddPatient" class="mx-3">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-6">
              <div class="form-group ">
                <label for="" class="mt-3">First name<span class="star">*</span></label>
                <input type="text" class="form-control" v-model="firstName"
                  v-on:keyup.enter="isFormFamilyInvalid ? saveFamilyData() : null">
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
              <div class="form-group">
                <label for="" class="mt-3">Last name<span class="star">*</span></label>
                <input type="text" class="form-control" v-model="lastName"
                  v-on:keyup.enter="isFormFamilyInvalid ? saveFamilyData() : null">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="" class="mt-3">Age in years<span class="star">*</span></label>
                <input type="number" class="form-control" v-model="age"
                  v-on:keyup.enter="isFormFamilyInvalid ? saveFamilyData() : null">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="" class="mt-3">Gender<span class="star">*</span></label>
                <div>
                  <select class="form-control" name="gender" id="gender" v-model="selectedGender"
                    v-on:keyup.enter="isFormFamilyInvalid ? saveFamilyData() : null">
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Others">Others</option>
                  </select>
                </div>
              </div>
            </div>
            <label for="" class="mt-3">Relationship with user<span class="star">*</span></label>
            <div class="col-6">
              <select class="form-control" v-model="relation"
                v-on:keyup.enter="isFormFamilyInvalid ? saveFamilyData() : null">
                <option value="1">Select</option>
                <option value="Father">Father</option>
                <option value="Mother">Mother</option>
                <option value="Son">Son</option>
                <option value="Daughter">Daughter</option>
                <option value="wife">Wife</option>
                <option value="husband">Husband</option>

              </select>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-5 mb-0 col-md-3 col-lg-3">
              <button class="btn btn-blue-color text-white" :disabled="!isFormFamilyInvalid"
                @click="saveFamilyData()">Add</button>
            </div>
          </div>
          <!-- <hr class="hr-border" /> -->
        </div>
        <div class="row my-3" v-if="isHide">
          <button type="button" @click="patientSupportData()" :disabled="openAddPatient"
            class="btn btn-blue-color text-white addConsultBtn">Add consultation</button>
        </div>
        <div class="pt-2 mx-3 mt-3" v-if="patientSupport == true" style="    border: 3px solid #00979e">
          <div class="d-flex justify-content-end me-2">
            <button type="button" @click="cancelCustom(value)" style="font-size: 10px" id="btn_schedule_add"
              class="btn btn_edit fs-3">
              <font-awesome-icon :icon="['fas', 'window-close']" />
            </button>
          </div>
          <div class="row mt-3">
            <div class="col-md-12 col-12">
              <h3 class="text-center">{{ date }}</h3>
            </div>
          </div>
          <div v-for="(records, index) in upload_Record" :key="index">
            <div class="row mt-2" style="margin:5px;">
              <div class="row mt-2">
                <div class="col-sm-12">
                  <p class="mb-0">Patient's chief complaint</p>
                </div>
              </div>
              <div class="row my-3">
                <div class="col-md-12 col-12">
                  <div id="CaseSheet" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner">
                      <form class="caseSheetTextAreas">
                        <textarea class="doctorWritenPlace" v-model="records.chiefComplaint"></textarea>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-sm-12">
                  <p class="mb-0">Clinical signs and symptoms</p>
                </div>
              </div>
              <div class="row my-3">
                <div class="col-md-12 col-12">
                  <div id="CaseSheet" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner">
                      <form class="caseSheetTextAreas">
                        <textarea class="doctorWritenPlace" v-model="records.ClinicalSignsSymptoms"></textarea>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-sm-12">
                  <p class="mb-0">Diagnosis/ Differential diagnosis</p>
                </div>
              </div>
              <div class="row my-3">
                <div class="col-md-12 col-12">
                  <div id="CaseSheet" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner">
                      <form class="caseSheetTextAreas">
                        <textarea class="doctorWritenPlace" v-model="records.DiagnosisDifferentialDiagnosis"></textarea>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-lg-3 col-sm-6 col-10">
                  <label class="" for="date">Upload prescription</label>
                  <input type="file" class="form-control " @change="fileSelect($event, index)">
                </div>
                <div class="col-lg-1 col-2 mt-4 my-2 ">
                  <span class="text-align text-center plus-icon" style="font-size:30px">
                    <font-awesome-icon @click="isShowAddConsult()"
                      :class="[!isConsultationDataValid ? 'disableCreateBtn' : '']" :icon='["fas", "plus-circle"]' />
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="form-group mt-3">
                <label class="" for="date">Next consultation date<span class="star">*</span></label>
              </div>
              <div class="col-lg-3 col-sm-4 col-12">
                <div class="form-group">
                  <input type="date" id="datepicker" :min="new Date().toISOString().split('T')[0]" class="form-control"
                    v-model="records.nextConsultation_date" />
                </div>
              </div>
              </div>
             
              <div class="col-12 col-lg-4 mt-3 ">
                <button class="btn btn-blue-color text-white" @click="createFootwearData()">Create footwear
                  order</button>
              </div>



              <div class="col-sm-12 mt-2" v-if="createFootwear == true">
                <div class="row">
                  <div class="col-sm-12">
                    <p class="text-center page-heading">Footwear order specifications</p>
                  </div>
                </div>

                <div class="row my-12 ">
                  <div class="col-md-4"></div>
                  <div class="col-md-3  ">
                    <label for="modelSelect" class="form-label">Height</label>
                    <div class="" style="display: flex; align-items: center;">
                      <input type="text " class="doctorWritenPlace" style="width: 60px;" v-model="height">
                      <label style="margin: 0px 5px ">cm</label>
                    </div>
                  </div>

                  <div class="col-md-3 col-12">
                    <label for="modelSelect" class="form-label">Weight</label>
                    <div style="display: flex; align-items: center;">
                      <input type="text" class="doctorWritenPlace" style="width: 60px;" v-model="weight">
                      <label style="margin: 0px 5px;">kg</label>
                    </div>
                  </div>

                  <div class="col-md-3"></div>
                </div>


                <div class="row my-12">
                  <div class="col-md-3"></div>
                  <div class="col-md-3 col-12">
                    <fieldset class="curve-box">
                      <legend class="subHeadingText">Foot arch</legend>
                      <div class="row my-2">
                        <div class="col-6 ">
                          <label>Left foot</label>
                        </div>
                        <div class="col-6 my-1">
                          <select class="form-control foot-arch" style="cursor: pointer;" v-model="footArch.left">
                            <option value="high">High</option>
                            <option value="normal">Normal</option>
                            <option value="low">Low</option>
                          </select>
                        </div>
                        <div class="col-6">
                          <label>Right foot</label>
                        </div>
                        <div class="col-6">
                          <select class="form-control" style="cursor: pointer;" v-model="footArch.right">
                            <option value="high">High</option>
                            <option value="normal">Normal</option>
                            <option value="low">Low</option>
                          </select>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <!-- Joint Mobility Section -->
                  <div class="col-md-3 col-12">
                    <fieldset class="curve-box">
                      <legend class="subHeadingText">Joint mobility</legend>
                      <div class="row my-2">
                        <div class="col-6">
                          <label>Left foot</label>
                        </div>
                        <div class="col-6 my-1">
                          <select class="form-control" style="cursor: pointer;" v-model="jointMobility.left">
                            <option value="normal">Normal</option>
                            <option value="hyper">Hyper</option>
                            <option value="stiff">Stiff</option>
                          </select>
                        </div>
                        <div class="col-6">
                          <label>Right foot</label>
                        </div>
                        <div class="col-6">
                          <select class="form-control" style="cursor: pointer;" v-model="jointMobility.right">
                            <option value="normal">Normal</option>
                            <option value="hyper">Hyper</option>
                            <option value="stiff">Stiff</option>
                          </select>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <div class="col-md-3"></div>
                </div>

                <div class="row my-12">
                  <div class="col-2 col-lg-3 "></div>
                  <div class="col-12 col-lg-6  col-md-6">

                    <leftFootPad @image-uploaded="handleLeftImageUpload" ></leftFootPad>

                  </div>


                  <div class="col-3 col-lg-3 "></div>
                </div>

                <div class="row my-12">
                  <div class="col-2 "></div>
                  <div class="col-8 ">
                  </div>
                  <div class="col-2 "></div>

                </div>

                <div class="row my-12">
                  <div class="col-1"></div>
                  <div class="col-10 ">
                    <h5 style="margin-left: 260px">Areas to offload</h5>
                    <table class="offload-table">
                      <thead>
                        <tr>
                          <th></th>
                          <th class="foot-column text-center">Left foot</th>
                          <th class="foot-column  text-center">Right foot</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(label, key) in areasToOffload" :key="key">
                          <td>{{ key }}</td>
                          <td>
                            <div class="text-center">
                              <input type="checkbox" :checked="areasToOffload[key].left === 'Yes'"
                                @change="updateAreaToOffload(key, 'left', $event)" />

                            </div>
                          </td>
                          <td>
                            <div class="text-center">
                              <input type="checkbox" :checked="areasToOffload[key].right === 'Yes'"
                                @change="updateAreaToOffload(key, 'right', $event)" />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-1"></div>
                </div>



                <div class="row my-12">
                  <div class="col-1 "></div>
                  <div class="col-10 ">
                    <h5 style="margin-left: 260px">Offloading methods</h5>
                    <table class="offload-table">
                      <thead>
                        <tr>
                          <th></th>
                          <th class="foot-column  text-center">Left foot</th>
                          <th class="foot-column  text-center ">Right foot</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(label, key) in Offloading_methods" :key="key">
                          <td>{{ key }}</td>
                          <td>
                            <div class="text-center">
                              <input type="checkbox" :checked="Offloading_methods[key].left === 'Yes'"
                                @change="updateOffloadingMethods(key, 'left', $event)" />
                            </div>
                          </td>
                          <td>
                            <div class="text-center">
                              <input type="checkbox" :checked="Offloading_methods[key].right === 'Yes'"
                                @change="updateOffloadingMethods(key, 'right', $event)" />
                            </div>

                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="col-1 "></div>
                </div>

                <div class="row my-12">
                  <div class="row mt-2">
                    <div class="col-sm-12">
                      <p class="mb-0" style="margin-left: 170px;">Offloading - Recommendations</p>
                      <p class="mb-0" style="margin-left: 170px;">(like a rocker, ankle support, or long Velcro straps,
                        etc.)</p>
                    </div>
                  </div>
                  <div class="row my-3">
                    <div class="col-md-12 col-12">
                      <div id="CaseSheet" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                          <form class="caseSheetTextAreas">
                            <textarea class="doctorWritenPlace" v-model="Offloading_Recommendations"></textarea>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="col-1 "></div>


                <div class="row my-12">
                  <div class="col-3"></div>
                  <div class="col-md-6 col-12 ">
                    <fieldset class="curve-box">
                      <legend class="subHeadingText">Footwear </legend>
                      <div class="row 12 " v-if="gender === 'Male'">
                        <div class="col-4"></div>
                        <div class="col-4 ">
                          <label for="modelSelect" class="form-label">Size (UK)</label>
                          <select v-model="selectedSize"   class="form-control consultation-select-inventory ">
                                <option disabled value="">Select size</option>
                                  <option value= "5">5</option>
                                  <option value= "5.5">5.5</option>
                                  <option value= "6">6</option>
                                  <option value= "6.5">6.5</option>
                                  <option value= "7">7</option>
                                  <option value= "7.5">7.5</option>
                                  <option value= "8">8</option>
                                  <option value= "8.5">8.5</option>
                                  <option value= "9">9</option>
                                  <option value= "9.5">9.5</option>
                                  <option value= "10">10</option>
                                  <option value= "10.5">10.5</option>
                                  <option value= "11">11</option>
                                  <option value= "11.5">11.5</option>
                                  <option value= "12.5">12.5</option>
                                  <option value= "13.5">13.5</option>
                                  <option value= "14.5">14.5</option>
                                  <option value= "15.5">15.5</option>
                            </select>
                        </div>
                        <div class="col-4"></div>
                      </div>

                       <div class="row 12 " v-if="gender === 'Female'" >
                        <div class="col-4"></div>
                        <div class="col-4 ">
                          <label for="modelSelect" class="form-label">Size (UK)</label>
                          <select v-model="selectedSize" class="form-control consultation-select-inventory ">
                                <option disabled value="">Select size</option>
                                  <option value= "2">2</option>
                                  <option value= "2.5">2.5</option>
                                  <option value= "3">3</option>
                                  <option value= "3.5">3.5</option>
                                  <option value= "4">4</option>
                                  <option value= "4.5">4.5</option>
                                  <option value= "5">5</option>
                                  <option value= "5.5">5.5</option>
                                  <option value= "6">6</option>
                                  <option value= "6.5">6.5</option>
                                  <option value= "7">7</option>
                                  <option value= "7.5">7.5</option>
                                  <option value= "8">8</option>
                                  <option value= "8.5">8.5</option>
                                  <option value= "9">9</option>
                                  <option value= "9.5">9.5</option>
                                  <option value= "10">10</option>
                                  <option value= "10.5">10.5</option>
                            </select>
                        </div>
                        <div class="col-4"></div>
                      </div> 

                       <div class="row 12 " v-if="gender === 'Others'" >
                        <div class="col-4"></div>
                        <div class="col-4 ">
                          <label for="modelSelect" class="form-label">Size (UK)</label>
                          <select v-model="selectedSize" class="form-control consultation-select-inventory ">
                                <option disabled value="">Select size</option>
                                  <option value= "2">2</option>
                                  <option value= "2.5">2.5</option>
                                  <option value= "3">3</option>
                                  <option value= "3.5">3.5</option>
                                  <option value= "4">4</option>
                                  <option value= "4.5">4.5</option>
                                  <option value= "5">5</option>
                                  <option value= "5.5">5.5</option>
                                  <option value= "6">6</option>
                                  <option value= "6.5">6.5</option>
                                  <option value= "7">7</option>
                                  <option value= "7.5">7.5</option>
                                  <option value= "8">8</option>
                                  <option value= "8.5">8.5</option>
                                  <option value= "9">9</option>
                                  <option value= "9.5">9.5</option>
                                  <option value= "10">10</option>
                                  <option value= "10.5">10.5</option>
                                  <option value= "11">11</option>
                                  <option value= "11.5">11.5</option>
                                  <option value= "12.5">12.5</option>
                                  <option value= "13.5">13.5</option>
                                  <option value= "14.5">14.5</option>
                                  <option value= "15.5">15.5</option>
                                 


                            </select>

                        </div>
                        <div class="col-4"></div>
                      </div> 

                      <div class="row my-12">
  <div class="col-4"></div>
  <div class="col-4 ">
    <label for="modelSelect" class="form-label">Model</label>
    <select v-model="selectedModelNo" @change="updateImagesPreview" class="form-control consultation-select-inventory ">
      <option disabled value="">Select model</option> 
      <option v-for="(item, index) in filteredModels" :key="index" :value="item.model_no">
        {{ item.model_no }}
      </option>
    </select>
  </div>
  <div class="col-4"></div>
</div>

<div class="row mt-3 mb-3" v-if="imagesPreview.length">
  <div class="">
    <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner text-align text-center">
        <div v-for="(image, index) in imagesPreview" :key="index"
          :class="['carousel-item', { active: index === 0 }]">
          <img :src="image" class="img-carousel-public">
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</div>
                    
                      <div class="row 12">
                        <div class="col-4"></div>

                          <div class="col-4 ">
                           <label class="form-label">Colour</label>
                              <select class="form-control consultation-select-inventory " @change="updateImagesColour"   v-model="userSelected">
                                <option disabled value="">Select colour</option>
                                <option v-for="(item, index) in filteredColors" :key="index" :value="item.id">
                                 {{ item.colour_name }}
                               </option>
                            </select>

                            <div class="d-flex justify-content-center" v-if="selectedColorImage">
                                 <img :src="selectedColorImage" alt="Selected Color" class="selected-color-image" />
                            </div>
                          </div>
                        <div class="col-4"></div>

                     </div>


                    </fieldset>
                  </div>
                  <div class="col-3"></div>
                </div>


                <div class="row mt-3">
                  <div class="col-3"></div>
                  <div class="col-md-6 col-12">
                    <fieldset class="curve-box">
                      <legend class="subHeadingText">Upload files</legend>
                      <div class="row my-2 ">
                        <div class="col-lg-1"></div>
                        <div class="col-6 col-lg-6">
                          <div class="col-lg-8 col-md-7 col-sm-7 col-12 my-3">
                            <button class="btn btn-blue-color text-white" @click="selectFile('ppsPdfFile')">PPS PDF
                              document</button>
                            <input type="file" id="ppsPdfFile" style="display: none;"
                              @change="handleFileChange($event, 'ppsPdfFile')" />
                            <span v-if="uploadedFiles.ppsPdfFile" class="text-success">Uploaded</span>
                            <br>
                            <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->

                          </div>
                        </div>
                        <div class="col-lg-1"></div>

                        <div class="col-6 col-lg-4 ">
                          <div class="col-lg-7 col-md-7 col-sm-7 col-12 my-3">
                            <button class="btn btn-blue-color text-white" @click="selectFile('ppsVideoFile')">PPS
                              video</button>
                            <input type="file" id="ppsVideoFile" style="display: none;"
                              @change="handleFileChange($event, 'ppsVideoFile')" />
                            <span v-if="uploadedFiles.ppsVideoFile" class="text-success">Uploaded</span>
                            <br>
                            <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div class="col-3"></div>
                </div>

                <div class="row my-12">
                  <div class="col-3"></div>
                  <div class="col-md-6 col-12">
                    <fieldset class="curve-box">
                      <legend class="subHeadingText">Gait videos</legend>
                      <div class="row my-2">
                        <div class="col-lg-2"></div>
                        <div class="col-4 col-lg-3">
                          <div class="col-lg-7 col-md-7 col-sm-7 col-12 my-3">
                            <button class="btn btn-blue-color text-white"
                              @click="selectFile('anteriorVideoFile')">Anterior</button>
                            <input type="file" id="anteriorVideoFile" style="display: none;"
                              @change="handleFileChange($event, 'anteriorVideoFile')" />
                            <span v-if="uploadedFiles.anteriorVideoFile" class="text-success">Uploaded</span>
                            <br>
                            <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->
                          </div>
                        </div>
                        <div class="col-4 col-lg-3">
                          <div class="col-lg-7 col-md-7 col-sm-7 col-12 my-3">
                            <button class="btn btn-blue-color text-white"
                              @click="selectFile('posteriorVideoFile')">Posterior</button>
                            <input type="file" id="posteriorVideoFile" style="display: none;"
                              @change="handleFileChange($event, 'posteriorVideoFile')" />
                            <span v-if="uploadedFiles.posteriorVideoFile" class="text-success">Uploaded</span>
                            <br>
                            <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->
                          </div>
                        </div>
                        <div class="col-4 col-lg-3 ">
                          <div class="col-lg-7 col-md-7 col-sm-7 col-12 my-3">
                            <button class="btn btn-blue-color text-white"
                              @click="selectFile('bilateralVideoFile')">Bilateral</button>
                            <input type="file" id="bilateralVideoFile" style="display: none;"
                              @change="handleFileChange($event, 'bilateralVideoFile')" />
                            <span v-if="uploadedFiles.bilateralVideoFile" class="text-success">Uploaded</span>
                            <br>
                            <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div class="col-3"></div>
                </div>


                <!-- Foot photographs section -->
                <div class="row my-12">
                  <div class="col-3"></div>
                  <div class="col-md-6 col-12">
                    <fieldset class="curve-box">
                      <legend class="subHeadingText">Foot photographs</legend>
                      <div class="row my-2">
                        <div class=" col-lg-2"></div>
                        <div class="col-4 col-lg-3">
                          <div class="col-lg-7 col-md-7 col-sm-7 col-12 my-3">
                            <h5>Left</h5>
                            <button class="btn btn-blue-color text-white my-1"
                              @click="selectFile('leftLateralPhoto')">Lateral</button>
                            <input type="file" id="leftLateralPhoto" style="display: none;"
                              @change="handleFileChange($event, 'leftLateralPhoto')" />
                            <span v-if="uploadedFiles.leftLateralPhoto" class="text-success">Uploaded</span>
                            <br>
                            <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->

                            <button class="btn btn-blue-color text-white"
                              @click="selectFile('leftMedialPhoto')">Medial</button>
                            <input type="file" id="leftMedialPhoto" style="display: none;"
                              @change="handleFileChange($event, 'leftMedialPhoto')" />
                            <span v-if="uploadedFiles.leftMedialPhoto" class="text-success">Uploaded</span>
                            <br>
                            <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->

                          </div>
                        </div>
                        <div class="col-4  col-lg-3">
                          <div class="col-lg-7 col-md-7 col-sm-7 col-12 my-3">
                            <h5>Right</h5>
                            <button class="btn btn-blue-color text-white my-1"
                              @click="selectFile('rightLateralPhoto')">Lateral</button>
                            <input type="file" id="rightLateralPhoto" style="display: none;"
                              @change="handleFileChange($event, 'rightLateralPhoto')" />
                            <span v-if="uploadedFiles.rightLateralPhoto" class="text-success">Uploaded</span>
                            <br>
                            <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->

                            <button class="btn btn-blue-color text-white"
                              @click="selectFile('rightMedialPhoto')">Medial</button>
                            <input type="file" id="rightMedialPhoto" style="display: none;"
                              @change="handleFileChange($event, 'rightMedialPhoto')" />
                            <span v-if="uploadedFiles.rightMedialPhoto" class="text-success">Uploaded</span>
                            <br>
                            <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->

                          </div>
                        </div>
                        <div class="col-4  col-lg-3">
                          <div class="col-lg-7 col-md-7 col-sm-7 col-12 my-3">
                            <h5>B/L</h5>
                            <button class="btn btn-blue-color text-white my-1"
                              @click="selectFile('plantarPhoto')">Plantar</button>
                            <input type="file" id="plantarPhoto" style="display: none;"
                              @change="handleFileChange($event, 'plantarPhoto')" />
                            <span v-if="uploadedFiles.plantarPhoto" class="text-success">Uploaded</span>
                            <br>
                            <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->

                            <button class="btn btn-blue-color text-white my-1"
                              @click="selectFile('dorsalPhoto')">Dorsal</button>
                            <input type="file" id="dorsalPhoto" style="display: none;"
                              @change="handleFileChange($event, 'dorsalPhoto')" />
                            <span v-if="uploadedFiles.dorsalPhoto" class="text-success">Uploaded</span>
                            <br>
                            <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->

                            <button class="btn btn-blue-color text-white my-1"
                              @click="selectFile('anteriorPhoto')">Anterior</button>
                            <input type="file" id="anteriorPhoto" style="display: none;"
                              @change="handleFileChange($event, 'anteriorPhoto')" />
                            <span v-if="uploadedFiles.anteriorPhoto" class="text-success">Uploaded</span>
                            <br>
                            <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->

                            <button class="btn btn-blue-color text-white"
                              @click="selectFile('posteriorPhoto')">Posterior</button>
                            <input type="file" id="posteriorPhoto" style="display: none;"
                              @change="handleFileChange($event, 'posteriorPhoto')" />
                            <span v-if="uploadedFiles.posteriorPhoto" class="text-success">Uploaded</span>
                            <br>
                            <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->

                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div class="col-3"></div>
                </div>

                <!-- Foot sketch images section -->
                <div class="row my-12">
                  <div class="col-3"></div>
                  <div class="col-md-6 col-12">
                    <fieldset class="curve-box">
                      <legend class="subHeadingText">Foot sketch images</legend>
                      <div class="row my-2">
                        <div class="col-lg-3"></div>
                        <div class="col-6  col-lg-4">
                          <div class="col-lg-7 col-md-7 col-sm-7 col-12 my-3">
                            <button class="btn btn-blue-color text-white" @click="selectFile('leftFootSketch')">Left
                              foot</button>
                            <input type="file" id="leftFootSketch" style="display: none;"
                              @change="handleFileChange($event, 'leftFootSketch')" />
                            <span v-if="uploadedFiles.leftFootSketch" class="text-success">Uploaded</span>
                            <br>
                            <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->

                          </div>
                        </div>
                        <div class="col-6   col-lg-4">
                          <div class="col-lg-7 col-md-7 col-sm-7 col-12 my-3">
                            <button class="btn btn-blue-color text-white" @click="selectFile('rightFootSketch')">Right
                              foot</button>
                            <input type="file" id="rightFootSketch" style="display: none;"
                              @change="handleFileChange($event, 'rightFootSketch')" />
                            <span v-if="uploadedFiles.rightFootSketch" class="text-success">Uploaded</span>
                            <br>
                            <!-- <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span> -->

                          </div>
                        </div>
                        <div class="col-lg-2"></div>

                      </div>
                    </fieldset>
                  </div>
                  <div class="col-3"></div>
                </div>
              </div>

              <div class="hr-border my-3"></div>
            </div>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-7 col-12 my-3 ms-3">
            <button class="btn btn-blue-color text-white" :disabled="!isConsultationDataValid"
              @click="uploadData()">Save record</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import doctorPatientView from '../../user/records/doctorPatientView.vue'
import doctorPatientFamilyView from '../../user/records/doctorPatientFamilyView.vue'
import moment from 'moment';
import leftFootPad from './leftFoot.vue'
import axios from 'axios';
export default {
  components: {
    doctorPatientView,
    doctorPatientFamilyView,
    leftFootPad,

  },
  props: ['mobileData', 'isUserCreated', 'newPublic', 'selected'],
  data() {
    return {
      filteredColors: [], 
      userRecordsData: {},
      doctorPayload: {},
      userSelected:"",
      colourData: [],
      selectedGender: '',
      uploadedLeftCanavasImageData: null,
      firstName: "",
      lastName: "",
      doctorData: {},
      uploadedLeftImage: null,
      uploadedRightImage: null,
      age: "",
      relation: "1",
      isHide: false,
      imagesPreview: [],
      selectMobileRecord: null,
      selectUserRecord: null,
      selectFamilyRecord: null,
      openAddPatient: false,
      previousConsultation: false,
      openAddConsultation: false,
      closeAddPatient: false,
      upload_Record: [{
        chiefComplaint: "",
        ClinicalSignsSymptoms: "",
        DiagnosisDifferentialDiagnosis: "",
        caseSheet: "",
        nextConsultation_date: "",
        uploadImage: "",
      }],
      uploadedFiles: {
        ppsPdfFile: false,
        ppsVideoFile: false,
        anteriorVideoFile: false,
        posteriorVideoFile: false,
        bilateralVideoFile: false,
        leftLateralPhoto: false,
        leftMedialPhoto: false,
        rightLateralPhoto: false,
        rightMedialPhoto: false,
        plantarPhoto: false,
        dorsalPhoto: false,
        anteriorPhoto: false,
        posteriorPhoto: false,
        leftFootSketch: false,
        rightFootSketch: false,
      },
      patientSupport: false,
      createFootwear: false,
      date: '',
      upFamily: {},
      uploadRecord: [],
      isLoading: false,
      familyRecords: [],
      upMobileRecord: {},
      publicDoctorCommunityData: [],
      isNewUser: null,
      newUserData: {},
      isSelected: {},
      getUploadData: "",
      getModelId: null,
      getColourId:null,
      gender:"",
      selectedSize:'',
      inventoryData: [],
      selectedModelNo: '',
      Offloading_Recommendations: "",
      weight: "",
      height: "",
      size: "",
      doctorName:"",
      clinicId:"",
      clinicData:"",
      adminCutomerId:"",
      footArch: {
        left: '',
        right: '',
      },
      jointMobility: {
        left: '',
        right: '',
      },
      areasToOffload: {
        Hallux: { left: 'No', right: 'No' },
        '2nd-5th toes': { left: 'No', right: 'No' },
        '1st MPJ': { left: 'No', right: 'No' },
        '2nd MPJ': { left: 'No', right: 'No' },
        '3rd MPJ': { left: 'No', right: 'No' },
        '4th MPJ': { left: 'No', right: 'No' },
        '5th MPJ': { left: 'No', right: 'No' },
        'Lateral Midfoot': { left: 'No', right: 'No' },
        'Medial Midfoot': { left: 'No', right: 'No' },
        'Central Midfoot': { left: 'No', right: 'No' },
        'Complete Midfoot': { left: 'No', right: 'No' },
        'Complete Heel': { left: 'No', right: 'No' },
        'Posterior Heel': { left: 'No', right: 'No' },
        'None_Dont Know': { left: 'No', right: 'No' }
      },

      Offloading_methods: {
        Scooping: { left: 'No', right: 'No' },
        'Reverse Scooping': { left: 'No', right: 'No' },
        Redsitribution: { left: 'No', right: 'No' },
        'Rocker Outersole': { left: 'No', right: 'No' },
        'Wedge Outersole': { left: 'No', right: 'No' },
        Other: { left: 'No', right: 'No' },
        FS_Orthotist_decide: { left: 'No', right: 'No' }
      },

      files: {
        ppsPdfFile: null,
        ppsVideoFile: null,
        anteriorVideoFile: null,
        posteriorVideoFile: null,
        bilateralVideoFile: null,
        leftLateralPhoto: null,
        leftMedialPhoto: null,
        rightLateralPhoto: null,
        rightMedialPhoto: null,
        plantarPhoto: null,
        dorsalPhoto: null,
        anteriorPhoto: null,
        posteriorPhoto: null,
        leftFootSketch: null,
        rightFootSketch: null,
      },
      // errorMessage:""

    };
  },
  mounted() {

    if ("vue-drawing-canvas" in window.localStorage) {
      this.initialImage = JSON.parse(
        window.localStorage.getItem("vue-drawing-canvas")
      );
    }
  },      
  created: async function () {
    this.newUserData = this.newPublic
    this.isSelected = this.selected


    let DoctorUserId = localStorage.getItem("id");
          this.DoctorId = JSON.parse(DoctorUserId);
           if(this.DoctorId){
          await this.fetchDoctorClinicAndInventory();
                                     
     } 

     await this.getInventoryColor();
    if (this.newUserData?.id) {
      this.isNewUser = true
      this.openAddConsultation = true
    }
    this.getMobile = localStorage.getItem('mobile');
    this.getUserDetail();
    this.getUplodRecord();
    this.getFamilyData();
    this.date = this.printDate();
    this.publicData = this.mobileData;
    if (this.isUserCreated) {
      this.getUserDetail();
    }
  },

  computed: {
    filteredModels() {
      if (this.userRecordsData?.gender === 'Male') {
        return this.inventoryData.filter(item => item.model_no.startsWith('M'));
      } else if (this.userRecordsData?.gender === 'Female') {
        return this.inventoryData.filter(item => item.model_no.startsWith('L'));
      } 
        else {
        return this.inventoryData;
      }
    },
    selectedColorImage() {
    const selectedColor = this.colourData.find(item => item.id === this.userSelected);
    return selectedColor ? selectedColor.images[0] : null; 
  },
    isFormFamilyInvalid() {
      const nameRegex = /^[A-Za-z.\s]+$/;
      return (
        nameRegex.test(this.firstName) &&
        nameRegex.test(this.lastName) &&
        this.age <= 999 &&
        this.selectedGender !== "1" &&
        this.selectedGender.trim() !== "" &&
        this.relation !== "1" &&
        this.relation.trim() !== ""
      );
    },
    isConsultationDataValid() {
      if (this.upload_Record.length === 0) {
        return false;
      }

      for (let i = 0; i < this.upload_Record.length; i++) {
        const record = this.upload_Record[i];
        if (
          !record.chiefComplaint || !record.ClinicalSignsSymptoms || !record.DiagnosisDifferentialDiagnosis ||
          !record.nextConsultation_date
        ) {
          return false;
        }
      }

  //     for (let fileKey of this.files) {
  //   if (!this.uploadedFiles[fileKey]) {
  //     return false;
  //   }
  // }
      return true;
    }
  },
  methods: {

  
    async fetchDoctorClinicAndInventory() {
     try {
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;

    const doctorResponse = await axios.get(`${BASE_API_URL}/hcps/${this.DoctorId}/one`);
    this.doctorData = doctorResponse.data;
    this.doctorName=this.doctorData.firstName
    console.log(this.doctorName ,"this.doctorData ")
    this.clinicId = this.doctorData.clinic_hcp_id;

    const clinicResponse = await axios.get(`${BASE_API_URL}/clinic-consultation/${this.clinicId}/one`);
    this.clinicData = clinicResponse.data;
    this.adminCutomerId = this.clinicData.admin_customer_id.id;

    if (!this.adminCutomerId) {
      console.error("adminCutomerId is not set ");
      return;
    }
    const inventoryResponse = await axios.get(`${BASE_API_URL}/foot-secure/inventory`);
    this.inventoryData = inventoryResponse.data?.filter(item => item.admin_customer_id.id === this.adminCutomerId);
    this.inventoryData.forEach(item => {
      if (item.images && item.images.length > 0) {
        item.imagesPreview = item.images.map(imageName => {
          return `${BASE_API_URL}/path/to/images/${imageName}`;
        });
      } else {
        item.imagesPreview = [];
      }
    });
  } catch (error) {
    console.error("Error in fetchDoctorClinicAndInventory:", error);
  }
}
,

    handleLeftImageUpload(imageData) {
      this.uploadedLeftImage = imageData;
    },

    updateImagesPreview() {
      const selectedItem = this.inventoryData.find(item => item.model_no === this.selectedModelNo);
      this.getModelId = selectedItem
      this.imagesPreview = selectedItem ? selectedItem.images : [];

      this.filteredColors = this.colourData.filter(color =>selectedItem.colour_id.includes(color.id.toString()));
      this.selectedColorImage = selectedItem ? selectedItem.imagesPreview[0] : ''; 
      this.userSelected = '';
      this.selectedColorImage = '';  
    },

    updateImagesColour() {
      const selectedItem = this.colourData.find(item => item.id === this.userSelected);
      this.getColourId = selectedItem
    },
    updateAreaToOffload(area, side, event) {
      this.areasToOffload[area][side] = event.target.checked ? 'Yes' : 'No';
    },
    updateOffloadingMethods(method, side, event) {
      this.Offloading_methods[method][side] = event.target.checked ? 'Yes' : 'No';
    },

    async submitForm() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      const payload = {
        foot_arch: {
          left: this.footArch.left,
          right: this.footArch.right,
        },
        Joint_mobility: {
          left: this.jointMobility.left,
          right: this.jointMobility.right,
        },
        Areas_to_offload: this.areasToOffload,
        Offloading_methods: this.Offloading_methods,
        upload_files: {
          ppsPdfFile: this.files.ppsPdfFile,
          ppsVideoFile: this.files.ppsVideoFile,
        },
        gait_videos: {
          anteriorVideoFile: this.files.anteriorVideoFile,
          posteriorVideoFile: this.files.posteriorVideoFile,
          bilateralVideoFile: this.files.bilateralVideoFile,
        },
        foot_photographs: {
          leftLateralPhoto: this.files.leftLateralPhoto,
          leftMedialPhoto: this.files.leftMedialPhoto,
          rightLateralPhoto: this.files.rightLateralPhoto,
          rightMedialPhoto: this.files.rightMedialPhoto,
          plantarPhoto: this.files.plantarPhoto,
          dorsalPhoto: this.files.dorsalPhoto,
          anteriorPhoto: this.files.anteriorPhoto,
          posteriorPhoto: this.files.posteriorPhoto,
        },
        foot_sketch_images: {
          leftFootSketch: this.files.leftFootSketch,
          rightFootSketch: this.files.rightFootSketch,
        },
        Offloading_Recommendations: this.Offloading_Recommendations,
        selected_model: this.getModelId
      };


      this.isLoading = true;
      axios
        .post(`${BASE_API_URL}/footwear-order`, payload, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          if (response?.data) {
            console.log("success", response?.data)
          }
        })
    },

    selectFile(inputId) {
      document.getElementById(inputId).click();
    },
  //   handleFileChange(event, fileType) {
  //     // const file = event.target.files[0]; 
  // //     if (!file) return;

  // //     const validFileTypes = {
  // //   ppsPdfFile: ['application/pdf'],
  // //   ppsVideoFile: ['video/mp4'],
  // //   anteriorVideoFile: ['video/mp4'],
  // //   posteriorVideoFile: ['video/mp4'],
  // //   bilateralVideoFile: ['video/mp4'],
  // //   leftLateralPhoto: ['image/jpeg', 'image/png'],
  // //   leftMedialPhoto: ['image/jpeg', 'image/png'],
  // //   rightLateralPhoto: ['image/jpeg', 'image/png'],
  // //   rightMedialPhoto: ['image/jpeg', 'image/png'],
  // //   plantarPhoto: ['image/jpeg', 'image/png'],
  // //   dorsalPhoto: ['image/jpeg', 'image/png'],
  // //   anteriorPhoto: ['image/jpeg', 'image/png'],
  // //   posteriorPhoto: ['image/jpeg', 'image/png'],
  // //   leftFootSketch: ['image/jpeg', 'image/png'],
  // //   rightFootSketch: ['image/jpeg', 'image/png']
  // // }

  // // if (!validFileTypes[fileType].includes(file.type)) {
  // //   this.errorMessage = `Invalid file type. Please upload a ${validFileTypes[fileType].join(', ')} file.`;
  // //   return;
  // // }
  // // this.uploadedFiles[fileType] = file;
  // // this.errorMessage = '';
  //   },
  handleFileChange(event, fileType) {
      const file = event.target.files[0];
      if (file) {
        this.files[fileType] = file;
        this.uploadedFiles[fileType] = true;
      }
    },

    isShowAddConsult() {
      this.upload_Record.push({
        chiefComplaint: "",
        ClinicalSignsSymptoms: "",
        DiagnosisDifferentialDiagnosis: "",
        caseSheet: "",
        nextConsultation_date: null,
        uploadImage: null,
      });
    },
    addPatientDesk() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
        .get(`${BASE_API_URL}/add-patient`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(async (response) => {
          this.addPatientInfo = response.data;
          await this.addPatientInfo.map((data) => {
            if (data.mobile === this.getMobile) {
              this.publicData = data;
              this.dataMobile = data.mobile
            }
          })
          if (this.getMobile === this.dataMobile) {
            this.issubmit = true
            this.isNot = false
          }
          else {
            this.isNot = true
            this.issubmit = false
          }
        })
    },
    async getUserDetail() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
        .get(`${BASE_API_URL}/public-user/${this.mobileData?.id || this.newPublic?.id}/one`)
        .then((response) => {
          if (response?.data) {
            this.userRecordsData = response.data;
            this.gender=this.userRecordsData.gender
  
          }
        })
        .catch(function (error) {
          console.log(error?.response);
        });
    },

    changePublicFirstName() {
      let name = this.firstName.toLowerCase()
      return name.charAt(0).toUpperCase() + name.slice(1);
    },
    async saveFamilyData() {
      let familyPayload = {
        firstName: this.changePublicFirstName(),
        lastName: this.lastName.toUpperCase(),
        age: this.age,
        gender: this.selectedGender,
        relation: this.relation,
        users: this.userRecordsData,
        role: 'family',
        isDoctorAddUserFamily: true,
        hcp_id_otp: this.doctorPayload
      }
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
        .post(`${BASE_API_URL}/family-member/${this.userRecordsData.id}`, familyPayload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res?.data) {
            this.getFamilyData();
            this.isAddNewMember = false
            this.firstName = "";
            this.lastName = "";
            this.age = "";
            this.selectedGender = "1";
            this.relation = "1";
            this.role = "";
            this.users = "";
            this.usersFamily = "";
            this.$swal("Added")
            this.openAddPatient = false
            this.closeAddPatient = false
          }
        })
        .catch(function (error) {
          console.log(error?.res);
        });
    },
    openMobileRecord(value) {
      this.upMobileRecord = value
      this.isHide=true
      this.isNewUser = false
      this.patientSupport = false
      this.selectMobileRecord = value
      this.selectFamilyRecord = false
      this.openAddConsultation = true
      this.openAddPatient = false
      this.closeAddPatient = false
    },
    openFamilyRecords(value) {
      this.upFamily = value;
      this.patientSupport = false
      this.selectUserRecord = false
      this.selectMobileRecord = false
      this.isNewUser = false
      this.openAddPatient = false
      this.selectFamilyRecord = value
      this.openAddConsultation = true
      this.closeAddPatient = false
    },

    addPatient() {
      this.patientSupport = false
      this.selectMobileRecord = false
      this.isNewUser = false
      this.closeAddPatient = true
      this.selectFamilyRecord = false
      this.selectUserRecord = false
      this.openAddPatient = true
      this.previousConsultation = true
      this.openAddConsultation = false
    },

    patientSupportData() {
      this.isHide = false
      this.patientSupport = true

    },
    cancelCustom() {
      this.patientSupport = false
      this.isHide = true

    },

    
    createFootwearData() {
      this.createFootwear = true
    },
    printDate: function () {
      return new Date().toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      }).split(' ').join('-');
    },
    fileSelect(event, index) {
      this.upload_Record[index].uploadImage = event.target.files?.[0];
    },
    async compressImage(file) {
      if (file) {
        return new Promise((resolve) => {
          const quality = 0.8;
          const maxWidth = 1000; 
          const maxHeight = 1000; 
          const image = new Image();
          image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = maxWidth;
            canvas.height = maxHeight;
            ctx.drawImage(image, 0, 0, maxWidth, maxHeight);
            canvas.toBlob(
              (blob) => {
                const compressedFile = new File([blob], file.name, {
                  type: 'image/jpeg', 
                  lastModified: Date.now(),
                });
                resolve(compressedFile);
              },
              'image/jpeg', 
              quality
            );
          };
          image.src = URL.createObjectURL(file);
        });
      }
    },

    async uploadData() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      for (let i = 0; i < this.upload_Record.length; i++) {
        const compressedImage = await this.compressImage(this.upload_Record[i].uploadImage);
        if (compressedImage && compressedImage.size < 22240) {
          this.$swal("Image not clear. Please upload an image of better quality.");
          return;
        }

        this.date = moment(this.date).format("YYYY-MM-DD");

        let formData = new FormData();
        formData.append('doctor_name', this.doctorName);
        formData.append('doctor_id', this.DoctorId);
        formData.append(
      'caseSheet',
      `${this.upload_Record[i].chiefComplaint}, ${this.upload_Record[i].ClinicalSignsSymptoms}, ${this.upload_Record[i].DiagnosisDifferentialDiagnosis}`
    );
        formData.append('file', compressedImage);
        formData.append('isConsultation', 1);
        formData.append('nextConsultationDate', this.upload_Record[i].nextConsultation_date);
        formData.append('consultation_date', this.date);

        if (this.selectMobileRecord || this.newPublic?.id) {
          formData.append('user_id', this.selectMobileRecord?.id || this.newPublic?.id);
          formData.append('family_id', this.selectFamilyRecord?.id);
        } else {
          formData.append('family_id', this.selectFamilyRecord?.id);
          formData.append('user_id', this.selectFamilyRecord?.users?.id);
        }

        if (this.isSelected) {
          if (this.isSelected.type === 'clinic') {
            formData.append('clinic_id', this.isSelected.id);
          } else if (this.isSelected.type === 'home') {
            formData.append('home_id', this.isSelected.id);
          }
        }


        this.isLoading = true;

        try {
          const uploadResponse = await axios.post(`${BASE_API_URL}/upload-records`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          console.log(uploadResponse,"uploadResponse")
          this.getUploadData = uploadResponse?.data?.data?.id;

          if (uploadResponse?.data) {
            this.publicDoctorCommunityData = [];
            this.publicDoctorCommunityData.push(this.doctorPayload);
            this.publicDoctorCommunityData = this.publicDoctorCommunityData.concat(this.selectMobileRecord?.doctorsID || this.selectFamilyRecord?.users?.doctorsID);

            const signInPayload = {
              doctorsID: this.publicDoctorCommunityData,
            };

            await axios.put(`${BASE_API_URL}/public-user/${this.selectMobileRecord?.id || this.selectFamilyRecord?.users?.id}/update`, signInPayload, {
              headers: {
                "Content-Type": "application/json",
              },
            });

            this.$emit("clearAll", true);
            const footwearPayload = new FormData();
            const footArchPayload = {
              left: this.footArch.left,
              right: this.footArch.right,
            };
            const jointMobilityPayload = {
              left: this.jointMobility.left,
              right: this.jointMobility.right,
            };
            footwearPayload.append('foot_arch', JSON.stringify(footArchPayload));
            footwearPayload.append('Joint_mobility', JSON.stringify(jointMobilityPayload));
            footwearPayload.append('Areas_to_offload', JSON.stringify(this.areasToOffload));
            footwearPayload.append('Offloading_methods', JSON.stringify(this.Offloading_methods));
            footwearPayload.append('Offloading_Recommendations', this.Offloading_Recommendations);
            footwearPayload.append('upload_records_data', this.getUploadData);
            footwearPayload.append('patient_name', this.selectMobileRecord?.id);
            footwearPayload.append('selected_model', this.getModelId.model_no);
            footwearPayload.append('selected_colour_model', this.getColourId.id);
            footwearPayload.append('confirmed_model', this.getModelId.model_no);
            footwearPayload.append('order_status', "Created");
            footwearPayload.append('height', this.height);
            footwearPayload.append('weight', this.weight);
            footwearPayload.append('size', this.selectedSize);
            footwearPayload.append('admin_customer_id', this.adminCutomerId);
            footwearPayload.append('upload_files[ppsPdfFile]', this.files.ppsPdfFile);
            footwearPayload.append('upload_files[ppsVideoFile]', this.files.ppsVideoFile);
            footwearPayload.append('gait_videos[anteriorVideoFile]', this.files.anteriorVideoFile);
            footwearPayload.append('gait_videos[posteriorVideoFile]', this.files.posteriorVideoFile);
            footwearPayload.append('gait_videos[bilateralVideoFile]', this.files.bilateralVideoFile);
            footwearPayload.append('foot_photographs[leftLateralPhoto]', this.files.leftLateralPhoto);
            footwearPayload.append('foot_photographs[leftMedialPhoto]', this.files.leftMedialPhoto);
            footwearPayload.append('foot_photographs[rightLateralPhoto]', this.files.rightLateralPhoto);
            footwearPayload.append('foot_photographs[rightMedialPhoto]', this.files.rightMedialPhoto);
            footwearPayload.append('foot_photographs[plantarPhoto]', this.files.plantarPhoto);
            footwearPayload.append('foot_photographs[dorsalPhoto]', this.files.dorsalPhoto);
            footwearPayload.append('foot_photographs[anteriorPhoto]', this.files.anteriorPhoto);
            footwearPayload.append('foot_photographs[posteriorPhoto]', this.files.posteriorPhoto);
            footwearPayload.append('foot_sketch_images[leftFootSketch]', this.files.leftFootSketch);
            footwearPayload.append('foot_sketch_images[rightFootSketch]', this.files.rightFootSketch);
            if (this.uploadedLeftImage) {
          footwearPayload.append('uploadedDrawImage', this.uploadedLeftImage);
        }



            console.log(footwearPayload, "footwear payload-----------------------");

            const footwearOrderResponse = await axios.post(`${BASE_API_URL}/footwear-order`, footwearPayload, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });

            if (footwearOrderResponse?.data) {
              console.log("Success", footwearOrderResponse.data);
              window.location.reload()
            }
          }
        } catch (error) {
          console.error(error.response);
        } finally {
          this.isLoading = false;
        }
      }
    },

getInventoryColor() {
  const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
  axios
    .get(`${BASE_API_URL}/foot-secure/inventory/colour`)
    .then((response) => {
      this.colourData = response.data?.filter(item => item)
      this.colourData.forEach(item => {
        if (item.images && item.images.length > 0) {
          item.imagesPreview = item.images.map(imageName => {
            return `${BASE_API_URL}/path/to/images/${imageName}`; 
          });
        } else {
          item.imagesPreview = [];
        }
      });
    })
    .catch((error) => {
      console.error("Error fetching inventory:", error);
    });
},

    getUplodRecord() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
        .get(`${BASE_API_URL}/upload-records`)
        .then((response) => {
          if (response?.data) {
            return response.data
          }
        })
    },
    async getFamilyData() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      let payload = {
        mobile: this.userRecordsData.mobile,
      };
      await axios
        .post(`${BASE_API_URL}/public-user/get-sign`, payload)
        .then((response) => { return response.data })
      await axios
        .get(`${BASE_API_URL}/family-member`)
        .then((response) => {
          this.familyRecords = [];
          response.data?.map((data) => {
            if (data.users?.id === this.userRecordsData.id) {
              this.familyRecords.push(data)
            }
          })
        })
    }
  },
}
</script>
<style>
.offload-table {
  width: 60%;
  max-width: 600px;
  margin: auto;
}

.offload-table th,
.offload-table td {
  padding: 8px;
  width: 300px;
}

.foot-column {
  width: 100px;
}

.offload-table input[type="checkbox"] {
  transform: scale(0.8);
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: #00979e;
  width: 5rem;
  height: 5rem;
}

.img-carousel-public {
  width: 300px;
  height: 300px;
  object-fit: contain;
  image-rendering: -webkit-optimize-contrast;
}


.curve-box {
  margin-bottom: 20px;
}

.selected-color-image {
  margin-top: 10px;
  max-width: 100%; 
  height: 100px; 
  width: 100px; 

}


.button-container {
  margin-top: 10px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.disableCreateBtn {
  color: #dddddd !important;
  pointer-events: none;
  cursor: auto;
}

.form-control {
  border-color: #34989f;
}

.star {
  color: red;
  font-size: 20px;
}

.addConsultBtn {
  width: fit-content;
  text-align: center;
  margin: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.RegisterPatientText {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0px;
}

.patient-record-img {
  height: 50px;
  width: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

fieldset.curve-box {
  border: 2px solid #00979e;
  border-radius: 20px;
  padding: 15px;
  margin-top: 1.5rem;
}

legend.subHeadingText {
  padding: 4px 10px;
  margin-bottom: 0px;
  display: block;
  float: none;
  width: auto;
  font-size: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
}

th {
  background-color: #f4f4f4;
}

p.text-color.text-side-align {
  text-align: center;
}

.icon-select-img {
  margin-left: 20px;
}

.image-fix {
  border: 3px solid #00979e;
  height: 100%;
  padding: 7px;
}

h3.medical-record {
  margin-left: 10px;
}

.text-center.name-fix {
  text-align: center !important;
  font-size: large;
  font-weight: bold;
}

img.img-camera.icon {
  float: right;
  width: 30px;
  margin: 5px;
}

img.img-men.icon {
  width: 50%;
  display: block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

img.img-women.icon {
  width: 50%;
  display: block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

.nav.nav-tabs {
  padding-top: 15px;
}

.col-sm-12.usersStyle {
  margin: 0px 0px 0px 10px;
  background-color: #ffff;
  border-radius: 10px;
  padding-bottom: 15px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 16%), 0 1px 2px 0 rgb(0 0 0 / 12%);

}

.col-sm-12.mx-auto.div-space {
  margin-bottom: 30px;
}

.left-space-desktop {
  margin-left: 20px;
}

.text-border {
  background-color: #00979e;
  height: 4px;
  margin-bottom: 20px;
}
.consultation-select-inventory{
width: 180px !important;
font-size: 16px !important;
margin-left: auto;
margin-right: auto;
text-align: left;
margin-bottom: 5px;
-webkit-appearance: auto;
appearance:auto;
}

.nav-link {
  display: block;
  padding: 9px;
  margin: 5px;
  color: #0d6efd;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.nav-tabs .nav-link.active {
  color: white;
  background-color: #00979e;
  border-color: #dee2e6 #dee2e6 #fff;
}

.horizondalLine {
  height: auto;
}

textarea.doctorWritenPlace {
  width: 100%;
  height: 200px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 20px;
}

form.caseSheetTextAreas {
  width: 75%;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width:991px) {
  .RegisterPatientText {
    padding-top: 0;
  }

  .col-sm-12.usersStyle {
    margin: auto;
    background-color: #ffff;
    border-radius: none;
    padding-bottom: 15px;
    box-shadow: none;
  }

  .nav.nav-tabs {
    padding-top: 0px;
  }

  .left-space-desktop {
    margin-left: 0px;
  }
}

@media screen and (max-width:769px) {
  form.caseSheetTextAreas {
    width: 100%;
  }

}

@media screen and (max-width:576px) {
  .text-center.name-fix {
    font-size: 15px;
  }
}
</style>