<template>
  <fieldset class="curve-box" v-if="selectedFoot === 'left'">
    <legend class="subHeadingText">Anatomical location of the pathology/ symptom</legend>
    <div class="row">
      <div class="flex-row">
        <div class="source">
          <vue-drawing-canvas
            ref="leftCanvas"
            :width="409"
            :height="450"
            :stroke-type="strokeType"
            :line-cap="lineCap"
            :line-join="lineJoin"
            :fill-shape="fillShape"
            :eraser="eraser"
            :lineWidth="line"
            :color="color"
            :background-color="backgroundColor"
            :background-image="currentLeftFootImage"
            :watermark="watermark"
            saveAs="png"
            :lock="disabled"
            style="margin-left: 100px;"
            @mousemove="getCoordinateLeft"
            class="canvas"
          />
          <canvas ref="hiddenCanvas" style="display: none;"></canvas>
          <div class="row button-container">
            <div class="col-lg-1"></div>
            <div class="col-lg-2 mt-2">
              <select v-model="line">
                <option v-for="n in 25" :key="'option-' + n" :value="n">{{ n }}</option>
              </select>
            </div>

            <div class="col-lg-6  mt-2">
              <div class=" d-flex justify-content-evenly ">                                                                           
                <label>
                  <input type="radio" v-model="color" value="#0000FF" /> Blue
              </label>  
                <label>
                  <input type="radio" v-model="color" value="#FF0000" /> Red
                </label>
                <label>
                  <input type="radio" v-model="color" value="#008000" /> Green
                </label>
              </div>
            </div>

            <div class="col-lg-3 text-end">
              <button type="button" class="btn btn-blue-color text-white" @click.prevent="uploadImage('left', $event)">
                Save
              </button>
              <div>
                <span v-if="isSaved" class="text-success">Saved</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fieldset>


</template>

<script>
import leftRightImage from "@/assets/images/LF_&_RF.png";
import VueDrawingCanvas from "vue-drawing-canvas";

export default {
  components: {
    VueDrawingCanvas,
  },
  data() {
    return {
      selectedFoot: "left",
      line: 5,
      color: "#000000",
      strokeType: "dash",
      lineCap: "square",
      lineJoin: "miter",
      backgroundColor: "#FFFFFF",
      watermark: null,
      isSaved: false,
      currentLeftFootImage: leftRightImage,
      leftFootDrawing: null,
      rightFootDrawing: null,
      eraser: false,
      disabled: false,
    };
  },
  methods: {
    handleFootChange(newFoot) {
      // Save the current drawing based on selected foot
      this.saveCurrentDrawing();

      // Load the drawing of the newly selected foot
      if (newFoot === "left") {
        this.currentLeftFootImage = this.leftFootDrawing || leftRightImage;
      } 
    },

    saveCurrentDrawing() {
      if (this.selectedFoot === "left") {
        this.leftFootDrawing = this.$refs.leftCanvas.$el.toDataURL();
      }
    },

    uploadImage(foot) {
      const hiddenCanvas = this.$refs.hiddenCanvas;
      const canvas = foot === "left" ? this.$refs.leftCanvas.$el : this.$refs.rightCanvas.$el;

      if (!(canvas instanceof HTMLCanvasElement)) {
        console.error(`${foot} canvas is not a valid HTMLCanvasElement`);
        return;
      }

      hiddenCanvas.width = canvas.width;
      hiddenCanvas.height = canvas.height;

      const context = hiddenCanvas.getContext('2d');
      context.drawImage(canvas, 0, 0);
      const canvasImage = hiddenCanvas.toDataURL("image/png");

      console.log(canvasImage, "canvasImage");
      this.$emit('image-uploaded', canvasImage);
      if (canvasImage) {
        this.$swal("Image uploaded successfully");
        this.isSaved = true;



      }
    },

    getCoordinateLeft(event) {
      this.xLeft = event.offsetX;
      this.yLeft = event.offsetY;
    },
    getCoordinateRight(event) {
      this.xRight = event.offsetX;
      this.yRight = event.offsetY;
    },

    toggleEraser() {
      this.eraser = !this.eraser;
    },
  },
};
</script>

<style>
.foot-selection {
  margin-bottom: 10px;
  justify-content: center;
  display: flex;
}

.curve-box {
  margin-bottom: 20px;
}

.button-container {
  margin-top: 10px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rotate-on-mobile {
  transform: rotate(90deg);
  transform-origin: center center;
  width: 345px;
  height: 350px;
}
</style>
